import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import AdminContainer from '../../../layout/admin/container';
import NumberFormat from 'react-number-format';
import { useBreadcrumbs } from '../../../../contexts/breadcrumbs-provider';
import { useNotifications } from '../../../../contexts/notifications-provider';
import Heading from '../../../layout/admin/heading';
import { formatDay, capFirstLetter, formatDateTime } from '../../../../utils/helpers';
import { getCenterAdminToken } from '../../../../utils/auth';
import { getApiRequest } from '../../../../utils/request';
import Loadable from '../../../data/loadable';
import DataCard from '../../../layout/admin/data-card';
import Badge from '../../../badges/badge';
import FormModal from '../../../overlays/form-modal';
import SEO from '../../../layout/seo';
import TailwindMarkdown from '../../../common/tailwind-markdown';

function formatData(result) {
  const { name, league, price, session_fee: sessionFee, purchased, total_stock: totalStock, image, banner_image: banner, min_age: minAge, start_date: startDate, description, short_description: shortDescription, team_size: teamSize, num_weeks: numWeeks, num_games: numGames, schedule_info: scheduleInfo, status, created_at: created } = result;
  const { name: leagueName } = league;
  const data = [
    {
      label: 'Name',
      value: name,
    },
    {
      label: 'Status',
      value: status === 'active' ? <Badge text={capFirstLetter(status)} color="green" /> : <Badge text={capFirstLetter(status)} color="yellow" />,
    },
    {
      label: 'League',
      value: leagueName,
    },
    {
      label: 'Total Stock',
      value: totalStock,
    },
    {
      label: 'People Registered',
      value: purchased,
    },
    {
      label: 'Registration Price',
      value: <NumberFormat value={price} displayType="text" thousandSeparator prefix="$" />,
    },
    {
      label: 'Session Fee',
      value: sessionFee ? <NumberFormat value={sessionFee} displayType="text" thousandSeparator prefix="$" /> : 'None',
    },
    {
      label: 'Start Date',
      value: formatDay(startDate, 'M/D/Y'),
    },
    {
      label: 'Minimum Age',
      value: minAge,
    },
    {
      label: 'Team Size',
      value: teamSize ? teamSize : '--',
    },
    {
      label: 'Number of Weeks',
      value: numWeeks ? numWeeks : '--',
    },
    {
      label: 'Games Per Week',
      value: numGames ? numGames : '--',
    },
    {
      label: 'Cover Photo',
      value: <img src={image} className="w-full" />,
    },
    {
      label: 'Banner Photo',
      value: <img src={banner} className="w-full" />,
    },
    {
      label: 'Short Description',
      value: shortDescription,
    },
    {
      label: 'Long Description',
      value: (
        <div className="text-base text-gray-900 font-sans">
          <TailwindMarkdown source={description} />
        </div>
      ),
    },
    {
      label: 'Schedule Info',
      value: scheduleInfo,
    },
    {
      label: 'Created At',
      value: formatDateTime(created),
    },
  ];

  return data;
}

const CenterAdminProductView = ({ productId }) => {
  const [loading, setLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [data, setData] = useState(null);
  const [status, setStatus] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  const { addNotification } = useNotifications();

  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/app/center-admin/products',
        label: 'Products',
      },
      {
        href: `/app/center-admin/product/view?id=${productId}`,
        label: `View Product`,
      },
    ]);

    const init = async () => {
      setLoading(true);
      try {
        const result = await getApiRequest(`/center-admin/product/${productId}?password=${getCenterAdminToken()}`);
        // console.log(result, 'this is the result');
        const { status: newStatus } = result;
        setStatus(newStatus);
        const newData = formatData(result);
        setData(newData);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          return;
        }
        addNotification({
          type: 'error',
          body: 'There was an error loading the product.',
        });
        navigate('/app/center-admin/products');
      }
      setLoading(false);
    };

    init();
  }, [productId]);

  const addForm = {
    noContainer: true,
    type: 'post',
    postRoute: `/center-admin/product/${productId}/invite`,
    beforePost: (values) => {
      return {
        ...values,
        password: getCenterAdminToken(),
      };
    },
    successNotification: 'The link has been emailed to the provided address.',
    successHandler: (values, actions) => {
      actions.resetForm();
      setShowAddModal(false);
    },
    fields: [
      {
        name: 'email',
        label: 'Email',
        type: 'email',
        required: true,
      },
    ],
    submitLabel: `Send Invite Link`,
  };

  const actionLinks = [];
  
  if (status && status !== 'pending') {
    actionLinks.push({
      label: 'Show Registrations',
      href: `/app/center-admin/registrations?product_id=${productId}`,
      secondary: true,
    });
  }
  // if (status === 'active') {
  //   actionLinks.push({
  //     label: 'Send Invite Link',
  //     onClick: () => setShowAddModal(true),
  //   });
  // }


  return (
    <>
      <AdminContainer centerAdmin>
        <SEO title="View product" />
        <Heading title="View Product" links={actionLinks} />
        <Loadable loading={loading}>
          <div className="max-w-4xl mx-auto py-12">
            <DataCard title="League Product Information" subtitle="Full details about a league product." items={data} />
          </div>
        </Loadable>
      </AdminContainer>
      <FormModal
        open={showAddModal}
        form={addForm}
        cancel={() => setShowAddModal(false)}
        title="Send Invite Link"
        body="Enter an email address to have an invitation link sent."
      />
    </>
  );
}

export default CenterAdminProductView;
